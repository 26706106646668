import { createApp } from 'vue'
import VueObserveVisibility from 'vue-observe-visibility'
import { Router } from 'vue-router'

import { iamPredicates } from '@ankor-io/common/auth/client/predicates'
import '@ankor-io/common/tailwindcss/colours.css'
import { sentryVueInit } from '@ankor-io/sentry-conflux/src/sentry'

import App from '@/App.vue'
import { fetchConfig } from '@/config/fetch'
import { createAuthentication } from '@/iam/authentication'
import createRouter from '@/router'
import { createSearchClient } from '@/search/client'
import '@/style.css'
import { growthbookInstance } from '@/utils/growthbook'

//
// First load the config
const config = await fetchConfig()

const router: Router = createRouter(/*config*/)

const app = sentryVueInit(createApp(App), config.SENTRY)

//
const authenticationContext = await createAuthentication(config.AUTHENTICATION_CLIENT_OPTIONS)

// Install the navigation guards
router.beforeEach(async (to) =>
  iamPredicates(authenticationContext, config.AUTHENTICATION_CLIENT_OPTIONS).beforeEach(to),
)

app.use(config)
app.use(router)

app.use(authenticationContext)

//
const _token = await authenticationContext.getToken()
if (!_token) {
  authenticationContext.redirectToLogin()
}

try {
  const searchClient = await createSearchClient(_token, config.ALGOLIA.app_id)
  app.use(searchClient)
} catch (error) {
  authenticationContext.redirectToLogin()
}

//
app.use(VueObserveVisibility)

/**
 * Track vue application errors
 */
app.config.errorHandler = (err: any, vm: any, info: any) => {
  //@ts-ignore
  window.heap?.track('applicationError', {
    error: err.message,
    stack: err.stack,
    info: info,
    vm: vm,
  })
  console.log(err)
}

const growthbook = await growthbookInstance.createGrowthbookClient(
  {
    clientKey: config.GROWTHBOOK.clientKey,
    enableDevMode: true,
  },
  authenticationContext,
)

app.use(growthbook)
app.mount('#app')
